import React, { useState, useEffect } from 'react'
import PageTitle from '../components/pagetitle';
import CTA from '../components/cta';
import { Chart } from '../components/token/Chart';
import {getContractHandler,_getChain,_getAddress,useWalletHook,connectMetamask,watchTransaction} from '../utils/ethereum'
import {Constants} from '../utils/Config'
import useToast from '../utils/useToast'
import { BigNumber, Contract, providers, utils } from 'ethers'
import { useTranslation } from '../utils/contexts/Localization'
import { BackgroundImage } from '@pancakeswap/uikit';
import { Link } from 'react-router-dom';

import data from '../assets/fake-data/data-project2'

function Token(props) {

    const { toastSuccess, toastError } = useToast()
    const { t } = useTranslation();

    
    const [shouldGet, setShouldGet] = useState(0)

    const [shouldObtain, setShouldObtain] = useState(0)


    const [shouldMint, setShouldMint] = useState(0)

    const [loadding,setLoadding] = useState(false)

    const [buyNumber,setBuyBumber] = useState(10)

    const [myPersale, setMypersale] = useState(0)

    const [total, setTotal] = useState(0)

    const [totalTime, setTotalTime] = useState(0)


    const buyUseUSDT = async () => {
           
        try {
              setLoadding(true)

            await _getChain()
            const account = await _getAddress()
            await connectMetamask()

          const usdt = await getContractHandler('USDT')
          const persale = await getContractHandler('Persale')
    
         
    
          const myUSDT = await usdt.balanceOf(account)
    
          const approveUSDT = await usdt.allowance(account, Constants.Contract.Persale)

        /*   if (parseFloat(utils.formatUnits(myUSDT,6)) <= buyNumber) {
            toastError("USDT 不足")
            setLoadding(false)
            return;
          } */

          /* if (parseFloat(utils.formatUnits(approveUSDT,6)) <= buyNumber) {
            const hashApprove = await usdt.approve(Constants.Contract.Persale, "0xffffffffffffffffffffffffffffffffffffffff")

            watchTransaction(hashApprove.hash,(res)=>{
                buyUseUSDT()
            })
            
            return false;
          } */


          await getData5();

          if(myPersale!=0){
            toastSuccess(t('Already claimed!'))
            return false;
          }
    
     
    
         const hash = await persale.presale(  buyNumber)

          watchTransaction(hash.hash,(res)=>{
              console.log(res)
              setLoadding(false)
              getData()
              toastSuccess(t('Successfully submit!'))
          })
        
    
        } catch (e) {
            console.error(e)
            setLoadding(false)
            toastError(e?.reason) 
        }  
    
      }
    


      
    const obtanToken = async () => {

        try {
            

          await _getChain()
         await _getAddress()
            await connectMetamask()
 
          const persale = await getContractHandler('Persale')
          await persale.getToken2()
          toastSuccess(t('Successfully submit!'))
         
        } catch (e) {
    
          toastError(e?.message)
          console.error(e?.message)
        }
    
      }
    
     const getReward = async () => {

        try {
            
            await _getChain()
            await _getAddress()
               await connectMetamask()
    
             const persale = await getContractHandler('Persale')
             await persale.getToken()
             toastSuccess(t('Successfully submit!'))
        } catch (e) {
    
          toastError(e?.message)
          console.error(e?.message)
        }
    
      }


      const getNFT= async () => {

        try {
            await _getChain()
            await _getAddress()
               await connectMetamask()
    
             const persale = await getContractHandler('Persale')
             await persale.getNFT()
             toastSuccess(t('Successfully submit!'))
         
        } catch (e) {
    
          toastError(e?.message)
          console.error(e?.message)
        }
    
      }



      const getData5= async () => {

        try {
            const account = await _getAddress()
            const persale = await getContractHandler('Persale')
            if(!persale) return 
           const res1=await persale.userBuyNumber(account) 
          /*  setShouldGet((utils.formatUnits(res1,0)-utils.formatUnits(res2,0))*1000);
           setShouldMint(utils.formatUnits(res3,0));
           setShouldObtain(utils.formatUnits(res4,0)*10000); */ 
           setMypersale(utils.formatUnits(res1,0)) 
         
        } catch (e) {
         
          console.error(e?.message)
        }
    
      }

      
      const getData= async () => {

        try {


            await _getChain()
            const account = await _getAddress()
            await connectMetamask()

            const persale = await getContractHandler('Persale')
    
          
            console.log("persale",persale)
            if(!persale) return 

           const res1=await persale.userBuyNumber(account)

           const res2=await persale.totalSell()

           const res3=await persale.currentTokenDepositId()


          /*  const res3=await persale.canMint(account)
           const res4=await persale.buyNumber(account) */

           console.log(res1,res2 )

          /*  setShouldGet((utils.formatUnits(res1,0)-utils.formatUnits(res2,0))*1000);
           setShouldMint(utils.formatUnits(res3,0));
           setShouldObtain(utils.formatUnits(res4,0)*10000); */

           setMypersale(utils.formatUnits(res1,0))
           setTotal(utils.formatUnits(res2,0))
           setTotalTime(utils.formatUnits(res3,0))

         
        } catch (e) {
    
          toastError(e?.message)
          console.error(e?.message)
        }
    
      }


      useEffect(()=>{
          getData()
      },[])

   

    return (
        <div className='inner-page'>
    {<PageTitle title={t("Token")} />}

    <section className="tf-section tf-token pt60">
        <div className="container">
            <div className="row">  
                <div className="col-lg-6 col-md-6">
                    <div className="tf-title left mb40" data-aos-delay="500" data-aos="fade-up" data-aos-duration="800">
                        <h2 className="title">
                            {t("Statistics token")}
                        </h2>
                    </div>
                    <div className="desc-token" data-aos-delay="500" data-aos="fade-up" data-aos-duration="800">
                        <div className="total-token">
                            <p>{t("Total Supply")}: <span>10,000,000,000</span></p>
                            <p>{t("Public sale")}: <span> $0.1</span></p>
                        </div>
                        <ul className="describe_chart">
                            <li>
                                <img src={require ("../assets/images/chart/color_1.png")} alt="" />
                                <div className="desc">
                                    <p className="name">{t("liquidity Pool")}</p>
                                    <p className="number">15%</p>
                                </div>
                            </li>
                            <li>
                                <img src={require ("../assets/images/chart/color_2.png")} alt="" />
                                <div className="desc">
                                    <p className="name">{t("Persale")}</p>
                                    <p className="number">25%</p>
                                </div>
                            </li>
                            <li>
                                <img src={require ("../assets/images/chart/color_3.png")} alt="" />
                                <div className="desc">
                                    <p className="name">{t("Ecosystem")}</p>
                                    <p className="number">7.5%</p>
                                </div>
                            </li>
                            <li>
                                <img src={require ("../assets/images/chart/color_4.png")} alt="" />
                                <div className="desc">
                                    <p className="name">{t("Advisor")}</p>
                                    <p className="number">7.5%</p>
                                </div>
                            </li>
                            <li>
                                <img src={require ("../assets/images/chart/color_5.png")} alt="" />
                                <div className="desc">
                                    <p className="name">{t("Welfare")}</p>
                                    <p className="number">10%</p>
                                </div>
                            </li>
                            <li>
                                <img src={require ("../assets/images/chart/color_6.png")} alt="" />
                                <div className="desc">
                                    <p className="name">{t("Exchange")}</p>
                                    <p className="number">25%</p>
                                </div>
                            </li>
                            <li>
                                <img src={require ("../assets/images/chart/color_7.png")} alt="" />
                                <div className="desc">
                                    <p className="name">{t("Marketing")}</p>
                                    <p className="number">5%</p>
                                </div>
                            </li>
                            <li>
                                <img src={require ("../assets/images/chart/color_8.png")} alt="" />
                                <div className="desc">
                                    <p className="name">{t("Team")}</p>
                                    <p className="number">5%</p>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="col-md-12">
                    <div className="tf-title left mt17 mb40" data-aos="fade-up" data-aos-duration="800">
                        <h2 className="title">
                            {t("Welfare")}
                        </h2> 
                    </div>
                </div>
                <div className="col-md-12">
                    <div className="tokenomics-wrapper" data-aos="fade-up" data-aos-duration="800">
                        <div className="box">
                            <div className="td td1">
                               {/*  <p>{t("Category")}</p> */}
                                <p>{t("Welfare receive")}</p>
                            </div>
                           {/*  <div className="td td2">
                                <p>{t("Allocation")} USDT</p>
                                <p><span  style={{fontSize:"30px",cursor:"pointer"}} onClick={()=>{if(Number(buyNumber)>100){setBuyBumber(Number(buyNumber)-100)}}} >- &nbsp;</span>  <input type="number"  className='input-sm' value={buyNumber} required onChange={(e)=>{console.log("imout",e.target.value);if(e.target.value>0&&e.target.value<100000000){setBuyBumber(e.target.value)}else if(!e.target.value){setBuyBumber(null)}}}/>  <span onClick={()=>{setBuyBumber(Number(buyNumber)+100)}} style={{fontSize:"25px",cursor:"pointer"}}>&nbsp;+</span></p>  
                            </div> */}
                            <div className="td td3">
                                <p>{t("Number")} </p>
                                <p>{buyNumber*10} NVF</p>
                            </div>
                            {/* <div className="td td4">
                                <p>{t("Price")}</p>
                                <p>$0.1</p>
                            </div> */}
                            <div className="td td5">
                               {/*  <p>{t("option")}</p> */}
                                <p>  
                                    <button type="submit" style={{background:loadding?"#798DA3":"#a526f0"}} className="tf-button style2" onClick={buyUseUSDT}>
                                        {t("Receive")}
                                    </button>
                                </p>
                            </div>
                        </div>

                        <div className="box">
                            <div className="td td1">
                                <p>{t("My Welfare")}</p>
                            </div> 
                            {/* <div className="td td4">
                                <p>{t("total Pay")}</p>
                                <p>{myPersale} USDT</p>
                            </div> */}
                            <div className="td td3">
                                <p>{t("total Get")}</p>
                                <p>{myPersale *10 } NVF</p>
                            </div>
                        </div>

                        <div className="box">
                            <div className="td td1">
                                <p>{t("Total pre-sales")}</p>
                            </div>
                          {/*   <div className="td td4">
                                <p>{t("total payment")}</p>
                                <p>{total} USDT</p>
                            </div> */}
                            <div className="td td3">
                                <p>{t("total distribution")}</p>
                                <p>{total ?total* 10-30:0} NVF</p>
                            </div>
                            <div className="td td5">
                                <p>{t("Total times")}</p>
                                <p>{totalTime?totalTime-2:0} {t("Times")}</p>
                            </div>
                        </div>


             <section className="tf-section project_1">
                <div className="container">
                    <div className="row"> 
                        <div className="col-md-12">
                            <div className="tf-title left mb40" data-aos="fade-up" data-aos-duration="800">
                                <h2 className="title">
                                   
                                   {t("Collaborative platform")}
                                </h2>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="project_wrapper_1">
                                {
                                    data.slice(0,2).map(item => (
                                        <div key={item.id} className="project-box-style4">
                                            <div className="image">
                                                <div className="img_inner">
                                                    <img src={item.img} alt="" />
                                                    <img className='shape' src={require ('../assets/images/common/shape.png')} alt="Risebot" />
                                                </div>
                                                <div className="label">
                                                    {t("1ST Phase")}
                                                </div>
                                            </div>
                                            <div className="content">
                                                <h5 className="heading"><Link target='_blank' to={item.link}>{item.title}</Link></h5>
                                                <p className="desc">{t(item.desc)}</p>
                                                <ul>
                                                    <li>
                                                        <p className="text">Min allocation</p>
                                                        <p className="price">{item.price_1}</p>
                                                    </li>
                                                    <li>
                                                        <p className="text">Maximum</p>
                                                        <p className="price">{item.price_2}</p>
                                                    </li>
                                                   {/*  <li>
                                                        <p className="text">Access</p>
                                                        <p className="price">{item.price_3}</p>
                                                    </li> */}
                                                </ul>
                                            
                                            </div>
                                        </div>
                                    ))
                                }

                            </div>
                        </div>
                    </div>
                </div>
            </section>
                    </div>
                </div>
            </div>
        </div>
    </section>

    {<CTA />}

</div>

    );
}

export default Token;